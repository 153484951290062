






































































































import { Route } from 'vue-router'
import lodash, { cloneDeep, upperFirst } from 'lodash'

import FullBody from '@/components/FullBody.vue'

/**components */
import TextInput from '@/elements/TextInput.vue'

import Loading from '@/elements/Loading.vue'
import InputLabel from '@/elements/InputLabel.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import MemoInput from '@/elements/MemoInput.vue'
import YesNoInput from '@/elements/YesNoInput.vue'

import EditVolunteer from './vues/EditVolunteer.vue'
/** model support */
import SelectInput from '@/elements/SelectInput.vue'
import {
  getEmptyLeagueVolunteer,
  isImportedVolunteer,
  LeagueVolunteerSeedInfo,
  VolunteerID,
  VolunteerIDType,
} from '@/lib/support/models/LeagueVolunteer/data'
import { LeagueVolunteer } from '@/GeneratedTypes/LeagueVolunteer'

import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import { decomposeLeagueVolunteer } from '@/views/Programs/Volunteers/ext/decomposeutils'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import AddressRowBlock from '@/components/AddressRowBlock.vue'
import { IndividualAddress } from '@/GeneratedTypes/IndividualAddress'
import { getEmptyIndividualAddress } from '@/lib/support/models/IndividualAddress/data'
import { IndividualPhone } from '@/GeneratedTypes/IndividualPhone'
import { LeagueVolunteerRole } from '@/GeneratedTypes/LeagueVolunteerRole'
import VolunteerRoleManager from '@/views/Programs/Volunteers/vues/VolunteerRoleManager.vue'

import {
  getRouteCategoryName,
  routeActionToVolunteer,
  getLeagueType,
} from '@/views/Programs/Volunteers/ext/routeutils'
import {
  CategoryNameToCategory,
  CategoryNameToProgramName,
  categoryToRoles,
  categoryToTitle,
} from '@/views/Programs/Volunteers/ext/roleutils'
import { RolesEnum } from '@/lib/support/models/LeagueVolunteer/data'
import { composeLeagueVolunteer } from '@/views/Programs/Volunteers/ext/composeutils'
import PhonePanel from '@/components/PhonePanel.vue'
import VolunteerSearch from '@/components/VolunteerSearch.vue'
import { CoachGenderGrade } from '@/GeneratedTypes/CoachGenderGrade'
import { CoachPracticeAvailability } from '@/GeneratedTypes/CoachPracticeAvailability'

import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue'
import { interfaceNone, interfaceOr, roleToInterface } from '@/views/Programs/Volunteers/ext/interfaceutils'
import store from '@/store'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { CoachProduct } from '@/GeneratedTypes/CoachProduct'
import VolunteerOptionPanel from '@/views/Programs/Volunteers/vues/VolunteerOptionPanel.vue'
import { getEmptyLeagueCoach } from '@/lib/support/models/LeagueCoach/data'
import CheckboxGroupInput from '@/elements/CheckboxGroupInput.vue'
import { LeagueCoach } from '@/GeneratedTypes/LeagueCoach'
import { LeagueTypes } from '@/lib/common/LeagueTypes'
import CoachLinkSelect from './vues/CoachLinkSelect.vue'
import { CoachAssistantLink, empty as emptyCoachLink } from '@/GeneratedTypes/CoachAssistantLink'

export default defineComponent({
  components: {
    VolunteerOptionPanel,
    PhonePanel,
    VolunteerRoleManager,
    AddressRowBlock,
    VeeValidateForm,
    RadioGroupInput,
    ConfirmDeleteModal,
    VolunteerSearch,
    SelectInput,
    Loading,
    TextInput,
    CheckboxInput,
    CheckboxGroupInput,
    MemoInput,
    YesNoInput,
    InputLabel,
    EditVolunteer,
    FullBody,
    CoachLinkSelect,
  },
  setup(props, ctx) {
    /** props */
    /** actions */
    const {
      loadProgramVolunteers,
      getVolunteer,
      getNewTemplate: getTemplate,
      upsertVolunteer: upsert,
    } = store.dispatch.volunteers
    const currentLeague = computed(() => store.getters.leagues.currentItem)
    const currentCamp = computed(() => store.getters.camps.currentItem)
    const currentVolunteer = computed(() => store.getters.volunteers.currentItem)
    const volunteerTemplate = computed(() => store.getters.volunteers.volunteerUnderEdit)
    const currentVolunteerID = computed(() => volunteerTemplate.value?.individualID ?? 0)
    const programInStore = computed(() => store.getters.volunteers.currentProgram)

    const currentItem = computed(() => {
      if (routeType.value === 'camp') return currentCamp.value
      if (routeType.value === 'league') return currentLeague.value
    })

    /**
     * The route is based on how many arguments are passed.
     * new does not take a parameter so just replace the suggested route
     * with the new route.
     */
    function relativeRoute(newRoute: string): string {
      if (editMode.value.startsWith('new-volunteer')) {
        return newRoute
      }
      return '../' + newRoute
    }

    const programsInCoachPrefs = computed(
      () => editCoachPreferences.value.programs?.map((x) => x.typeProgramID) ?? []
    )

    const interfaceToggles = computed(() => {
      return editRoles.value
        .map((x) => roleToInterface(x.roleID))
        .reduce((x, y) => interfaceOr(x, y), interfaceNone)
    })

    /** private data */
    const editVolunteer = ref(cloneDeep(getEmptyLeagueVolunteer(new LeagueVolunteerSeedInfo())))
    const loading = ref(false)
    const editMode = ref('') // maps to routename, used for watcher on edit template
    const editAddress = ref<IndividualAddress>(getEmptyIndividualAddress())
    const editPhones = ref<IndividualPhone[]>([])
    const editRoles = ref<LeagueVolunteerRole[]>([])
    const editEmail = ref('')
    const leagueid = ref('') //aught to be set by the router.
    const editGrades = ref<CoachGenderGrade[]>([])
    const editPracticeDays = ref<CoachPracticeAvailability[]>([])
    const editProducts = ref<CoachProduct[]>([])
    const routeType = ref('')
    const editCoachPreferences = ref(getEmptyLeagueCoach())
    const getCurrentCoachLink = (): CoachAssistantLink => {
      return (
        editCoachPreferences.value.programs?.filter((p) => p.typeProgramID == selectedProgram.value)[0]
          ?.assistantCoachLink ?? { ...emptyCoachLink(), coach1IndividualID: currentVolunteerID.value }
      )
    }
    const setCurrentCoachLink = (v: CoachAssistantLink): void => {
      const prefs = cloneDeep(editCoachPreferences.value)
      const program = prefs.programs?.filter((p) => p.typeProgramID == selectedProgram.value)[0]
      if (program) {
        program.assistantCoachLink = v
        editCoachPreferences.value = prefs
      }
    }
    const editCoachLink = computed({
      get() {
        return getCurrentCoachLink()
      },
      set(v: CoachAssistantLink) {
        setCurrentCoachLink(v)
      },
    })

    const selectedProgram = computed(() => {
      const count = editPrograms.value.length
      return count == 2
        ? editPrograms.value.filter((p) => !p.toUpperCase().includes('CHEER'))[0]
        : editPrograms.value[0]
    })
    const selectedProgramDescription = computed(() => {
      const descRaw: String =
        programsAvailable.value.filter((p) => p.id == selectedProgram.value)[0]?.desc ?? ''
      return store.getters.leagueAbstraction.isUpwardSelect
        ? currentLeague.value.selectSportName
        : descRaw
            .split(/(\s+)/)
            .map((w: string) => {
              const upperFirst = w.charAt(0).toUpperCase()
              return upperFirst + w.substring(1).toLowerCase()
            })
            .join(' ')
    })

    function updateVolunteer(v: LeagueVolunteer) {
      editVolunteer.value = cloneDeep(v)
    }

    function emailUpdate(email: string) {
      editEmail.value = email
    }
    //when a change is requested, will hold the pending program.
    const pendingProgramChoice = ref<string[]>([])
    //modal for confirm program change
    const confirmChangeProgram = ref(false)

    function warnAboutProgramChange(v: string[]) {
      pendingProgramChoice.value = cloneDeep(v)
      //invalid to choose less than one.
      if (v && v.length && v.length < editPrograms.value.length) {
        //signal if the user is removing a program.
        confirmChangeProgram.value = true
      } else {
        changeProgram()
      }
    }
    const editPrograms = ref<string[]>([])
    //** what happens when we confirm a change program, should bubble to the volunteer options
    function changeProgram() {
      //invalid to choose < 1 choice.
      if (pendingProgramChoice.value.length) {
        editPrograms.value = cloneDeep(pendingProgramChoice.value)
      }
    }
    const titleCase = (s: string) => upperFirst(s.toLocaleLowerCase())

    const programsAvailable = computed(() => {
      if (currentItem.value?.programs?.length) {
        // first is sport name, second cheer, so friendly names like
        if (currentItem.value?.programs?.length == 2) {
          //if this returns more than two someday this assumption breaks.
          return [
            {
              id: currentItem.value?.programs[0].typeProgramID,
              desc: titleCase(currentItem.value?.programs[0].typeProgramID),
            },
            { id: currentItem.value?.programs[1].typeProgramID, desc: 'Cheer' },
          ]
        }

        return currentItem.value?.programs.map((x) => ({ id: x.typeProgramID, desc: x.typeProgramID }))
      }

      return []
    })

    /***
     * Triggered on the edit to take care of the UC for looking up a prior athelete.
     */
    const searchName = ref('') //command delimited name to lookup.
    function nameUpdated(first: string, last: string) {
      searchName.value = last + ',' + first
    }
    /**
     * Called when a volunteer is selected from a finder box
     * from name entry.
     */
    async function volunteerFound(id: VolunteerIDType) {
      if (VolunteerID(editVolunteer.value) != id) {
        const roles = cloneDeep(editRoles.value)

        await loadByID(id)
        if (volunteerTemplate.value) {
          editVolunteer.value = cloneDeep(volunteerTemplate.value)

          decomposeTemplate()
        }

        roles.forEach((role) => {
          if (editRoles.value.findIndex((x) => role.roleID == x.roleID) < 0) {
            editRoles.value.push(role)
          }
        })
      }
    }
    async function loadByID(id: number) {
      try {
        await getVolunteer({ id: id })
        if (volunteerTemplate.value) {
          editVolunteer.value = cloneDeep(volunteerTemplate.value)
        }
      } catch (error) {
        //no current volunteer, grab the template and start there.

        await getTemplate({ id })
        if (volunteerTemplate.value) {
          editVolunteer.value = cloneDeep(volunteerTemplate.value)
        }
        //volunteerTemplate will be set after getTemplate is called.
      }
    }

    const category = ref('')

    const title = computed(() => {
      return categoryToTitle(category.value.split('-')[0])
    })

    const isNew = computed(() => editMode.value.startsWith('new-volunteer'))

    const cancelLink = computed(() =>
      isNew.value ? `../list/${category.value}` : `../../list/${category.value}`
    )

    function addProgram(program: string) {
      if (editPrograms.value.indexOf(program) < 0) {
        editPrograms.value.push(program)
      }
    }

    async function routeChange(to: Route) {
      routeType.value = getLeagueType(to.fullPath)
      loading.value = true
      category.value = getRouteCategoryName(to)

      let cntpn = CategoryNameToProgramName(category.value)
      if (!cntpn) {
        // see if we have something in the store.
        cntpn = programInStore.value
      }
      if (!cntpn) {
        if (currentItem.value?.programs?.length) {
          cntpn = currentItem.value.programs[0].typeProgramID
        }
      }
      addProgram(cntpn)

      try {
        if (to?.params.id) {
          leagueid.value = to.params.id
          await loadProgramVolunteers({ leagueId: to?.params.id })
        }
        if (to?.params?.vid) {
          await loadByID(parseInt(to.params.vid))
        } else {
          //probably a new user, lets prospectively load a template.
          await getTemplate({})
        }
      } catch (e) {
        console.warn(e)
        await ctx.root.$router.push(relativeRoute('list'))
        throw new Error(e.message)
      }
      loading.value = false
      editMode.value = to.name ?? ''

      // if there is a current volunteer we get a head start on an edit.
      if (currentVolunteer.value) {
        editVolunteer.value = cloneDeep(routeActionToVolunteer(to.name, currentVolunteer.value))
      }
      reconcileTemplate()

      // we see if the thing we are editing has the roles we expect, if not we will ask which role.
      rolesToExpect.value = categoryToRoles(CategoryNameToCategory(getRouteCategoryName(to)))
      //remove ref commissioner for Upward Select
      if (currentItem.value?.typeLeagueID === LeagueTypes.UPWARDSELECT) {
        const index = rolesToExpect.value.indexOf(RolesEnum.REFEREECOMMISSIONER)
        if (index > -1) {
          rolesToExpect.value.splice(index, 1)
        }
      }
    }
    watch(() => ctx.root.$route, routeChange, { immediate: true, deep: true })
    const rolesToExpect = ref<number[]>([])

    const imported = ref(false)
    /***
     * If a template is updated or refreshed, then integrate it in with
     * the data we know about, editVolunteer ultimately gets the version of what we will edit.
     */
    watch(() => volunteerTemplate, reconcileTemplate)
    function reconcileTemplate() {
      if (volunteerTemplate.value) {
        editVolunteer.value = cloneDeep(volunteerTemplate.value)
        decomposeTemplate()
      }
    }

    watch(
      () => editCoachPreferences.value,
      () => {
        // we can add on coach preferences assignment but removal must be done through the UI.
        editPrograms.value = cloneDeep(lodash.uniq([...programsInCoachPrefs.value, ...editPrograms.value]))
      },
      { immediate: true }
    )

    const composedVolunteer = computed(() => {
      return composeLeagueVolunteer(editVolunteer.value, {
        volunteer: editVolunteer.value,
        roles: editRoles.value,
        phones: editPhones.value,
        address: editAddress.value,
        email: editEmail.value,
        coachPreferences: editCoachPreferences.value,
      })
    })

    function decomposeTemplate() {
      ;({
        phones: editPhones.value,
        address: editAddress.value,
        volunteer: editVolunteer.value,
        roles: editRoles.value,
        email: editEmail.value,
        coachPreferences: editCoachPreferences.value,
      } = decomposeLeagueVolunteer(editVolunteer.value))
    }

    function updatePrefsAndRoles(c: LeagueCoach, roles: LeagueVolunteerRole[]) {
      if (!lodash.isEqual(editCoachPreferences, c)) {
        editCoachPreferences.value = cloneDeep(c)
      }
      if (!lodash.isEqual(editRoles.value, roles)) {
        editRoles.value = cloneDeep(roles)
      }
    }

    /***
     * Possible lazy load of the template requires post-edit reconciliation.
     * @param newTemplate
     */
    watch(() => volunteerTemplate, decomposeTemplate)

    async function saveAndContinue(e: MouseEvent) {
      e.stopPropagation()
      e.preventDefault()

      if (isImportedVolunteer(editVolunteer.value)) {
        //assign valid league and account to imported volunteers
        editVolunteer.value.accountNumber = currentItem.value?.accounts?.length
          ? currentItem.value.accounts[0].accountNumber
          : '0'
        editVolunteer.value.leagueID = currentItem.value?.id ?? 0
        imported.value = true
      }

      try {
        await upsert({
          leagueID: leagueid.value,
          volunteer: composedVolunteer.value,
          forceNew: imported.value,
        })
      } catch (e) {
        throw e
      }

      await ctx.root.$router.push({
        name: `list-volunteer-${routeType.value}`,
        params: { type: category.value, id: leagueid.value },
      })
    }
    return {
      editRoles,
      editProducts,
      searchName,
      programsInCoachPrefs,
      confirmChangeProgram,
      changeProgram,
      volunteerFound,
      editPrograms,
      leagueid,
      editPracticeDays,
      editGrades,

      currentItem,
      currentLeague,
      rolesToExpect,
      editAddress,
      nameUpdated,
      editPhones,
      editEmail,
      updateVolunteer,
      emailUpdate,
      cancelLink,
      isNew,
      editVolunteer,
      saveAndContinue,
      loading,
      interfaceToggles,
      programsAvailable,
      updatePrefsAndRoles,
      warnAboutProgramChange,
      editCoachPreferences,
      title,
      editCoachLink,
      selectedProgram,
      selectedProgramDescription,
      currentVolunteerID,
      currentVolunteer,
    }
  },
})
